<template>
  <div id="file-viewer">
    <!-- header -->

    <div class="header">
      <div class="file-name">
        <slot name="file-name"></slot>
      </div>

      <BaseActionButton
        v-tooltip.top="'back'"
        is-flat
        icon="eva-arrow-back"
        @click.stop="$emit('back')"
      />

      <slot name="header-left"></slot>

      <q-space />

      <slot name="header-right"></slot>

      <BaseActionButton
        v-tooltip.top="'previous file'"
        is-flat
        icon="eva-arrow-back"
        class="q-mr-sm"
        @click.stop="$emit('previous')"
      />

      <BaseActionButton
        v-tooltip.top="'next file'"
        is-flat
        icon="eva-arrow-forward"
        @click.stop="$emit('next')"
      />
    </div>

    <!-- ... -->

    <!-- file -->

    <iframe :src="fileLink" frameborder="0"></iframe>

    <!-- <BaseScrollbar height="calc(100vh - 209px)">
      <div class="file-wrapper">
        <img
          v-for="n in 5"
          :key="n"
          src="@/assets/capture/thumbnail.png"
          alt="thumnail"
        />
      </div>
    </BaseScrollbar> -->

    <!-- ... -->

    <!-- footer -->

    <!-- <div class="footer">
      <div class="wrapper">
        <BaseIcon name="eva-maximize-outline" is-white class="q-mr-md" />

        <BaseIcon name="eva-minimize-outline" is-white />

        <BaseSeparator is-vertical has-inset class="q-mx-md" />

        <BaseIcon name="mdi-chevron-double-left" is-white class="q-mr-md" />

        <BaseIcon name="mdi-chevron-left" is-white />

        <BaseSeparator is-vertical has-inset class="q-mx-md" />

        <div class="page">1 of 23</div>

        <BaseSeparator is-vertical has-inset class="q-mx-md" />

        <BaseIcon name="mdi-chevron-right" is-white class="q-mr-md" />

        <BaseIcon name="mdi-chevron-double-right" is-white />

        <BaseSeparator is-vertical has-inset class="q-mx-md" />

        <BaseIcon name="eva-download-outline" is-white class="q-mr-md" />

        <BaseIcon name="eva-more-vertical-outline" is-white />
      </div>
    </div> -->

    <!-- ... -->

    <!-- <iframe
      src="http://localhost/FileViewer/index.html?uploadId=1"
      frameborder="0"
    ></iframe> -->
  </div>
</template>

<script>
import { pdfSupport, otherFilePDFSupport } from "@/helpers/file-format.js";
export default {
  name: "FileViewer",

  props: {
    file: {
      type: Object,
      required: true,
    },

    type: {
      type: Number,
      required: true,
    },
  },

  computed: {
    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.file.name) {
        let ext = this.file.name.split(".").pop();
        let domainURL = "";
        if (pdfSupport(ext)) {
          if (
            origin === "https://ag-appsvc01.azurewebsites.net" ||
            origin === "https://ag-appsvc05.azurewebsites.net"
          ) {
            domainURL = "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
          } else if (
            origin === "https://trial.ezofis.com" &&
            !otherFilePDFSupport(ext)
          ) {
            domainURL = "https://trial.ezofis.com/docsviewer/index.html";
          } else if (origin === "https://app.ezofis.com") {
            domainURL =
              "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
          } else {
            domainURL = `${origin}/PDFViewer/web/viewer.html`;
          }
        } else if (origin === "http://52.172.32.88") {
          domainURL = "http://52.172.32.88/DocsViewer/index.html";
        } else {
          return `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.file.id}/${this.type}`;
        }
        return `${domainURL}?tId=${this.$store.state.session.tenantId}&uploadId=${this.file.id}&type=${this.type}&filename=${this.file.name}`;
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#file-viewer {
  background-color: var(--body-bg-color);
  padding: 16px 16px 0px 16px;
  position: relative;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    position: relative;

    .file-name {
      font-weight: 600;
      position: absolute;
      width: 100%;
      text-align: center;
    }
  }

  .file-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;

    img {
      display: block;
      width: 60%;
      height: auto;
      margin-bottom: 16px;
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    .wrapper {
      height: 56px;
      width: 424px;
      background-color: $dark-3;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .page {
      color: white;
      font-weight: 500;
    }
  }

  iframe {
    width: 100%;
    height: calc(100vh - 197px);
  }
}
</style>
